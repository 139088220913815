<template>
  <type-template
    :gender="gender"
    :first-spots-occurrence="firstSpotsOccurrence"
    :spot-occurrence-values="spotOccurrenceValues"
    :facial-spot-locations="facialSpotLocations"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import TypeTemplate from '@/modules/questionnaire/components/steps/photo-analysis/skin-pigmentation/type/TypeTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';
import {
  SPOT_OCCURRENCE,
  SPOT_OCCURRENCE_VALUES_COMMON
} from '@/modules/questionnaire/api/constants';

import { female } from '@/modules/questionnaire/api/helpers';

const { field, requiredArrayField } = fieldBuilder;

const FIELDS = [
  field('age'),
  field('gender'),
  requiredArrayField('firstSpotsOccurrence'),
  requiredArrayField('facialSpotLocations')
];

export default {
  name: 'Type',
  components: { TypeTemplate },
  mixins: [makeStep(FIELDS)],
  computed: {
    spotOccurrenceValues() {
      const values = [...SPOT_OCCURRENCE_VALUES_COMMON];
      const age = +this.age;

      if (age > 40) {
        values.push(SPOT_OCCURRENCE.AT_MIDDLE_AGE);
      }

      if (age > 20 && female(this.gender)) {
        values.push(SPOT_OCCURRENCE.DURING_PREGNANCY);
      }

      if (age > 35 && female(this.gender)) {
        values.push(SPOT_OCCURRENCE.AFTER_STARTING_HRT);
      }

      if (female(this.gender)) {
        values.push(SPOT_OCCURRENCE.AFTER_STARTING_BIRTH_CONTROL);
      }

      return values;
    }
  },
  watch: {
    firstSpotsOccurrence() {
      this.scrollTo('#facial-spot-locations');
    }
  }
};
</script>
